<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6 ">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{ $t('message.payment_cancel_title') }}</h1>
            <img src="/img/ko.png" width="200" height="200" class="img-fluid m-5" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import ApiResolver from '../components/common/ApiResolver';
    import Api from '../components/common/Api';

    export default {
        name: 'payments-cancel',
        components: {},
        props: [
            'query'
        ],
        data() {
            return {};
        },
        computed: {},
        methods: {

        },
        mounted() {
            Api.post(ApiResolver.get('PAYMENT_CANCEL'), JSON.stringify({
              token: this.query.token,
              type: this.query.type,
              cartNumber: this.query.cartNumber,
              payload: this.query
            }))
                .then((response) => {


                })
                .catch((error) => {
                    // handle error
                    //console.log(error);


                });


        },
    };
</script>

<style scoped>
  .hasError label {
    color: red;
  }

  .error {
    color: red
  }
</style>


